<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="changeDate"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="amount">
      <div class="flex"><span>￥</span>总营业额:{{ total_turnover }}</div>
    </div>
    <div class="amount">
      <div class="flex"><span>￥</span>会员开通额:{{ vip_turnover }}</div>
    </div>
    <div class="amount">
      <div class="flex"><span>￥</span>产品销售额:{{ product_turnover }}</div>
    </div>
    <div class="amount">
      <div class="flex"><span>￥</span>曲谱销售额:{{ music_score_turnover }}</div>
    </div>

    <div class="chartBox">
      <div id="myChart" :style="{ width: '100%', height: '500px' }"></div>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order ? scope.row.order.user.nickname : "" }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.order && scope.row.order.user.avatar"
            :src="scope.row.order.user.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order ? scope.row.order.user.phone : "" }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">会员</span>
          <span v-else-if="scope.row.type == 2">产品</span>
          <span v-else-if="scope.row.type == 3">曲谱</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row ? scope.row.order_no : "" }}
        </template>
      </el-table-column> -->
      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.change }}
        </template>
      </el-table-column>
      <el-table-column label="时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_payout == 0" type="success">收入</el-tag>
          <el-tag v-else type="danger">支出</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import * as echarts from "echarts";
// let echarts = require("echarts/lib/echarts");
// // 引入折线图组件
// require("echarts/lib/chart/line");
// // 引入提示框和title组件
// require("echarts/lib/component/tooltip");
// require("echarts/lib/component/title");
// require("echarts/lib/component/legend");

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      dateArr: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      listQuery: {
        page: 1,
        limit: 10,
        start_datetime: "",
        end_datetime: "",
      },
      date: {
        start_datetime: "",
        end_datetime: "",
      },
      start_datetime: "",
      end_datetime: "",
      lastDay: "",
      total_recharge: "",
      total_vip: "",
      total_course: "",
      total_user_balance: "",
      total_discount_course: "",
      total_activity: "",
      total_discount_activity: "",
      total_turnover: "",
      product_turnover: "",
      vip_turnover: "",
      music_score_turnover:'',
      music_score_list:[],  
      product_list: [],
      vip_list: [],
      total_list: [],
    };
  },
  created() {
    this.month = new Date().getMonth() + 1;
    let MonthNextFirstDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      1
    );
    let MonthLastDay = new Date(MonthNextFirstDay - 86400000);
    this.lastDay = MonthLastDay.getDate();
    if (this.month < 10) {
      this.start_datetime =
        new Date().getFullYear() + "-" + "0" + this.month + "-" + "01";
      this.end_datetime =
        new Date().getFullYear() + "-" + "0" + this.month + "-" + this.lastDay;
    } else {
      this.start_datetime =
        new Date().getFullYear() + "-" + this.month + "-" + "01";
      this.end_datetime =
        new Date().getFullYear() + "-" + this.month + "-" + this.lastDay;
    }
    this.listQuery.start_at = this.start_datetime;
    this.listQuery.end_at = this.end_datetime;
    // this.drawLine();
    this.getDataGraph();
    this.getUserAccountLog();
    this.getDataCount();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    changeDate(data) {
      console.log(data, "=========");
      this.dateArr=data
      // let date = data.split("-");
      this.start_datetime = data[0];
      // new Date().getFullYear() + "-" + date[1] + "-" + "01";
      this.end_datetime = data[1];
      // new Date().getFullYear() + "-" + date[1] + "-" + date[2];
      // this.getDataGraph();
    },
    async getDataGraph() {
      this.date.start_datetime = this.start_datetime
        ? this.start_datetime + " 00:00:00"
        : "";
      this.date.end_datetime = this.end_datetime
        ? this.end_datetime + " 23:59:59"
        : "";
      //画图表
      await request({
        url: "/api/backend/finance/dataGraph",
        method: "get",
        params: this.listQuery,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          res.data.product_list.forEach((item) => {
            this.product_list.push(Math.floor(item * 100) / 100);
          });
          res.data.vip_list.forEach((item) => {
            this.vip_list.push(Math.floor(item * 100) / 100);
          });
          res.data.total_list.forEach((item) => {
            this.total_list.push(Math.floor(item * 100) / 100);
          });
          res.data.music_score_list.forEach((item) => {
            this.music_score_list.push(Math.floor(item * 100) / 100);
          });
          // this.vip_list = res.data.vip_list;
          // this.total_list = res.data.total_list;
          this.$forceUpdate(); //强制更新
          this.drawLine();
          // }
        }
      });
    },

    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let start_datetime = this.date.start_datetime.split(" ")[0];
      let end_datetime = this.date.end_datetime.split(" ")[0];
      let date = this.get(start_datetime, end_datetime);
      console.log(date);
      let myChart = echarts.init(document.getElementById("myChart"));
      let temporary = [];
      for (let i = 0; i < date.length; i++) {
        temporary.push(date[i]);
      }
      // 绘制图表
      myChart.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["总营业额", "会员开通额", "产品营业额","曲谱营业额"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: 10,
          feature: {
            saveAsImage: {
              show: true,
              title: "保存为图片",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: ['周一','周二','周三','周四','周五','周六','周日']
          data: temporary,
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "总营业额",
            type: "line",
            stack: "总量",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.total_list || [],
          },
          {
            name: "会员开通额",
            type: "line",
            stack: "会员",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.vip_list || [],
          },
          {
            name: "产品营业额",
            type: "line",
            stack: "产品",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.product_list || [],
          },
          {
            name: "曲谱营业额",
            type: "line",
            stack: "曲谱",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.music_score_list || [],
          },
        ],
      });
    },

    get(day1, day2) {
      let end_day = day2.split("-")[1] + "/" + day2.split("-")[2];
      //  console.log(end_day)
      var getDate = function (str) {
        var tempDate = new Date();
        var list = str.split("-");
        tempDate.setFullYear(list[0]);
        tempDate.setMonth(list[1] - 1);
        tempDate.setDate(list[2]);
        return tempDate;
      };
      var date1 = getDate(day1);
      var date2 = getDate(day2);
      if (date1 > date2) {
        var tempDate = date1;
        date1 = date2;
        date2 = tempDate;
      }
      date1.setDate(date1.getDate());
      var dateArr = [];
      var i = 0;
      while (
        !(
          date1.getFullYear() == date2.getFullYear() &&
          date1.getMonth() == date2.getMonth() &&
          date1.getDate() == date2.getDate()
        )
      ) {
        var dayStr = date1.getDate().toString();
        let monthStr = date1.getMonth() + 1;
        if (dayStr.length == 1) {
          dayStr = "0" + dayStr;
        }
        if (monthStr < 10) {
          monthStr = "0" + monthStr;
        }
        dateArr[i] = monthStr + "/" + dayStr; // date1.getFullYear() + "/" +
        i++;
        /*
         * document.write("<div style='display:block'>" + date1.getFullYear() +
         * "-" + (date1.getMonth() + 1) + "-" + date1.getDate() + "</div>");
         */
        // document.write(dateArr[i] + "<br>");
        date1.setDate(date1.getDate() + 1);
      }
      // dateArr.splice(0,0,day1)
      dateArr.push(end_day);
      // console.log(dateArr)
      return dateArr;
    },
    getUserAccountLog() {
      this.listLoading = true;
      request({
        url: "/api/backend/finance/orderLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getDataCount() {
      this.listLoading = true;
      request({
        url: "/api/backend/finance/dataCount",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.vip_turnover = response.data.vip_turnover;
        this.product_turnover = response.data.product_turnover;
        this.music_score_turnover = response.data.music_score_turnover;
        this.total_turnover = response.data.total_turnover;
          // Math.floor(response.data.total_turnover * 100) / 100;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getDataCount();
      this.getUserAccountLog();
      this.getDataGraph();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getUserAccountLog();
      this.getDataCount();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getUserAccountLog();
      this.getDataCount();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.amount {
  display: inline-block;
  padding: 20px 30px;
  margin-bottom: 20px;
  margin-right: 20px;
  color: #ffffff;
  background-color: rgba(255, 0, 0, 0.438);
  border-radius: 40px;
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>
